const ip = "relteco.com"//"172.16.64.144"

export const ApiConfig = {
    apis:{
        "base":{
            "ssl":true,
            "domain":{"dev":ip,"pub":"172.16.64.156"},
            "port":{"dev":3005,"pub":3005},
            "endpoint":"/"
           }
    },
    base:"https://"+ip+":3005/",
    file:"https://"+ip+":3005/FILES"
}