import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CssBaseline } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { ToggleThemeProvider } from './Hooks/usetheme';
import history from './history';
import { BrowserRouter } from 'react-router-dom';
import "./i18n"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter history={history} >
    <ToggleThemeProvider>
        <CssBaseline>
          <SnackbarProvider autoHideDuration={3000} maxSnack={3}>
            <App />
          </SnackbarProvider>
        </CssBaseline>
    </ToggleThemeProvider>
</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
