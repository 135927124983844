
import { Box, IconButton, useTheme } from "@mui/material"
import React, { useEffect, useState } from 'react'
import { MenuButton } from "../Elements/MenuButton";
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { motion } from "framer-motion"
import { useLocation, useNavigate } from "react-router-dom";

const MenuItems = ({ mode = "lt" }) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [l, setl] = useState("tr")

  const [moden, setMode] = useState({
    lt: { display: { xs: "none", sm: 'none', md: "flex" }, width: "100%", height: 40, justifyContent: "center", alignItems: "center" },
    lf: { width: "100%", marginTop: 2, overflow: "auto", height: "90%" }
  })

  function OpenPage(name) {
    navigate("/" + name);
  }

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setl(lng)
  };


  useEffect(() => {
    changeLanguage("tr")
  }, [])

  return (
    <Box sx={mode === "lt" ? moden.lt : moden.lf}>
      <MenuButton onClick={() => OpenPage("SolutionsPage")} mode={mode} text={t('menu.solutions')} />
      <MenuButton onClick={() => OpenPage("ProductsPage")} mode={mode} text={t('menu.products')} />
      <MenuButton onClick={() => OpenPage("DownloadsPage")} mode={mode} text={t('menu.downloads')} />
      <MenuButton onClick={() => OpenPage("ContactPage")} mode={mode} text={t('menu.contact')} />
      <MenuButton onClick={() => OpenPage("")} mode={mode} text={t('menu.relol')} />
      <MenuButton onClick={() => OpenPage("")} mode={mode} text={t('menu.login')} />
      {l === "tr"
        ? <MenuButton mode={mode} onClick={() => { changeLanguage("en") }} text={"EN"} />
        : <MenuButton mode={mode} onClick={() => { changeLanguage("tr") }} text={"TR"} />}

      {mode === "lf" && <MenuButton mode={mode} text={""} />}

    </Box>

  )
}



export const MenuView = () => {

  const theme = useTheme()
  const [leftmenu, setleftmenu] = useState(false)

  const location = useLocation(); // link değiştiğinde left menüyü kapat
  useEffect(() => {
    setleftmenu(false)
  }, [location]);

  return (
    <>
      <MenuItems />

      <Box sx={{ display: { xs: "flex", sm: 'flex', md: "none" }, width: "100%", height: 40, justifyContent: "flex-end", alignItems: "center" }} >
        <IconButton
          onClick={() => { setleftmenu(!leftmenu) }}
          size="large"
          edge="start"
          color={theme.palette.text.primary}
          aria-label="open drawer"
          sx={{ mr: 1 }}
        >
          <MenuIcon />
        </IconButton>

      </Box>
      <LeftMenu closeLeftMenu={() => { setleftmenu(!leftmenu) }} display={leftmenu} />
    </>
  )
}







export function LeftMenu({ display = false, closeLeftMenu }) {
  const theme = useTheme()

  const variants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: "-100%" },
  }




  return (
    <motion.nav
      animate={display ? "open" : "closed"}
      variants={variants}
      style={{ position: "absolute", zIndex: 998, backgroundColor: theme.palette.background.default, width: "100%", height: "100vh", top: 0 }} >
      <Box sx={{ width: "100%", height: 40, display: "flex", justifyContent: "flex-end", alignItems: "center" }} >
        <IconButton
          onClick={closeLeftMenu}
          size="large"
          edge="start"
          color={theme.palette.text.primary}
          aria-label="open drawer"
          sx={{ mr: 1 }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <MenuItems mode="lf" />

    </motion.nav>
  )
}