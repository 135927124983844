import React from 'react'
import { Rtitle } from '../Components'
import { useTranslation } from 'react-i18next';
import { Box, useTheme } from '@mui/material';
import { InLayout } from '../Layouts/InLayout';
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet'



export  function ContactPage() {
  const { t, i18n } = useTranslation();
  const theme =useTheme()
  const map = {
    center:[ 
      39.933541,
      32.840452
    ],
    zoom: 17
  };

  return (
    
    <InLayout>


<MapContainer style={{ height:"80%", width: '100%',minHeight:150,position:"relative" }} center={map.center} zoom={map.zoom} scrollWheelZoom={false}>
              <TileLayer
                url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker position={map.center}>
                <Popup>
                Konumu  <a target='_blank' href="https://www.google.com/maps/place/%C4%B0lkirem+Lavanta+Bah%C3%A7esi/@40.8613863,35.2449688,15z/data=!4m6!3m5!1s0x4086e352b6b556c7:0x28674df1c9d35e04!8m2!3d40.8613863!4d35.2449688!16s%2Fg%2F11y1d37ypp?entry=ttu">Haritalar</a> Uygulamasında Aç
                </Popup>
              </Marker>
            <Box sx={{width:"100%",height:20,position:"absolute",bottom:0,backgroundColor:theme.palette.background.paper,left:0,zIndex:6666}} ></Box>
            </MapContainer>

    </InLayout>

  )
}
