

const themeModel= (MODE) => ({
 
  palette: {
    mode:MODE? "dark":"light",
    primary:{
     // main:"#15181c"
      main:"#E94057"

    },
    background:{
      default:MODE?"#15181c":"#d2e2f7",
      paper:MODE?"#121314":"#ccddff",
    },
  },
})

export default themeModel;