import React from 'react'
import { Reldroid } from '../Components'
import { Typography, Box } from '@mui/material'
import { useTranslation } from 'react-i18next';
import { Rtitle } from '../Components';

export function HomePage() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Reldroid />
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }} >
        <Rtitle text={t('slogan.p1')} />
        <Rtitle text={t('slogan.p2')} />
      </Box>
    </div>
  )
}
