import React from 'react'
import { Rtext, Rtitle } from '../Components'
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

export  function InLayout({title,children,subtitle}) {
  const { t, i18n } = useTranslation();

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center",overflow:"auto",marginTop:7 }} >

    <Rtitle text={t(title)} />
    <Box sx={{width:{sm:"80%",md:"60%"},mt:2}} >
    <Rtext text={t(subtitle)}/>
    </Box>
    {children}
  </Box>
  )
}
