import { createContext, useContext, useMemo, useState } from "react";

import { useLocalStorage } from "./useLocalStorage";


const ThemeContext = createContext();

export const ToggleThemeProvider = ({ children }) => {
  const [themeMode, setThemeMode] = useLocalStorage("theme", false);


  const toggleThemeMode = async () => {
    setThemeMode(!themeMode);
  };


  const value = useMemo(
    () => ({
        themeMode,
      toggleThemeMode
    }),
    [themeMode]
  );


  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
};

export const useToggleTheme = () => {
  return useContext(ThemeContext);
};