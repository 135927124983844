import React from 'react'
import { Rtext, Rtitle } from '../Components'
import { useTranslation } from 'react-i18next';
import { Box, Grid, Icon, useTheme } from '@mui/material';
import { InLayout } from '../Layouts/InLayout';


function Card({ title, icon }) {
  const theme = useTheme()
  const { t, i18n } = useTranslation();

  return (
    <Box sx={{ mt: 1, height: 100, p: 2, borderRadius: 2, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", backgroundColor: "rgba(0,0,0,0.3)" }} >
      <Icon sx={{ width: 80, height: 60, fontSize: 60, color: "orange" }} > {icon} </Icon>
      {/* <Rtitle text={"web tasrım"}/> */}
      <Rtext fs={18} text={t(title)} />


    </Box>
  )
}

export function SolutionsPage() {

  const SolutionsList = [
    [
      { name: "solutionPage.tags.p0", icon: "public" },
      { name: "solutionPage.tags.p1", icon: "api" },
      { name: "solutionPage.tags.p2", icon: "apartment" },
    ], [
      { name: "solutionPage.tags.p3", icon: "phone_iphone" },
      { name: "solutionPage.tags.p4", icon: "phone_android" },
      { name: "solutionPage.tags.p5", icon: "3d_rotation" },
    ], [
      { name: "solutionPage.tags.p6", icon: "diamond" },
      { name: "solutionPage.tags.p7", icon: "computer" },
      { name: "solutionPage.tags.p8", icon: "camera" },
    ], [
      { name: "solutionPage.tags.p9", icon: "view_in_ar" },
      { name: "solutionPage.tags.p10", icon: "storage" },
      { name: "solutionPage.tags.p11", icon: "groups" },
    ]


  ]

  return (
    <InLayout subtitle={"solutionPage.subtitle"} title={"solutionPage.title"} >

      <Grid container spacing={2}>
        <Grid item xs={12} sx={12} sm={6} md={4} lg={3} >

          {SolutionsList && SolutionsList[0].map((d, i) => {
            return (
              <React.Fragment key={i}>
                <Card title={d.name} icon={d.icon} />
              </React.Fragment>
            )
          })}

        </Grid>
        <Grid item xs={12} sx={12} sm={6} md={4} lg={3} >
          {SolutionsList && SolutionsList[1].map((d, i) => {
            return (
              <React.Fragment key={i}>
                <Card title={d.name} icon={d.icon} />
              </React.Fragment>
            )
          })}

        </Grid>
        <Grid item xs={12} sx={12} sm={6} md={4} lg={3} >
          {SolutionsList && SolutionsList[2].map((d, i) => {
            return (
              <React.Fragment key={i}>
                <Card title={d.name} icon={d.icon} />
              </React.Fragment>
            )
          })}

        </Grid>
        <Grid item xs={12} sx={12} sm={6} md={4} lg={3} >
          {SolutionsList && SolutionsList[3].map((d, i) => {
            return (
              <React.Fragment key={i}>
                <Card title={d.name} icon={d.icon} />
              </React.Fragment>
            )
          })}
        </Grid>


      </Grid>


    </InLayout>
  )
}
