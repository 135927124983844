import { AXIOS } from "../AXIOS"
import { APIS } from "./apis"


export const DB =()=>{

    const { Responsive } = AXIOS()


    function GetProducts(h, data, callback) {
        let l = APIS.GetProducts
        Responsive(h, l.startpoint + l.endpoint, data, (r) => {
          callback(r)
        })
      }

      function GetDownloads(h, data, callback) {
        let l = APIS.GetDownloads
        Responsive(h, l.startpoint + l.endpoint, data, (r) => {
          callback(r)
        })
      }





      return{
        GetProducts,GetDownloads
      }

}