import Model from "../../../lib/Model/model";
import { ContactShadows, PresentationControls, Environment, useProgress, Loader, AdaptiveDpr, Html } from "@react-three/drei";
import React, { Suspense, useRef, useEffect, useState } from 'react'
import { Canvas } from "@react-three/fiber";
import { Box } from '@mui/material';

export const Reldroid = () => {
    
    function Loadern() {
        const { active, progress, errors, item, loaded, total } = useProgress()
        return ( 
            <>
            <div style={{color:"red",fontSize:40}} >Yükleniyor</div>
            <Html center>{progress} % loaded</Html>
            </> 
        )
      }
    const [mx, setmx] = useState(0)
    const [my, setmy] = useState(0)

    useEffect(() => {

        window.addEventListener('mousemove', e => {

            let x = e.clientX,
                y = e.clientY, WW = window.innerWidth,
                WH = window.innerHeight,
                centerX = WW / 2,
                centerY = WH / 2;

            // -1------ 0 (centerX) -----1
            setmx(r(centerX, x))
            //         setmy( y>centerY ? r(centerY,y) : r(centerY/2,y))
            setmy(r(centerY / 2, y))

        });

    }, [])

    function r(b, v) {

        let a = b - v
        return -a * 0.15 / 100;
    }


    return (
        <Box sx={{ width: "100%", height: "60%",position:"relative" }} >
            <Box sx={{ width: "100%", height: "90%",position:"absolute",zIndex:3,bottom:0,left:0 }} ></Box>
            <Box sx={{ width: "100%", height: "100%" }}   >
                <Loader />
                <Canvas style={{width:"100vw"}}  dpr={[1, 2]} camera={{ position: [0, 0, 2.6] }} >
                    <ambientLight   intensity={-1} />

                        {/* <ambientLight color={"black"}  intensity={10} /> */}
                    {/* <directionalLight  intensity={20} color={"white"} position={[0,100,0]}/> */}
                  

                        <PresentationControls
                            global
                            config={{ mass: 2, tension: 500 }}
                            snap={{ mass: 2, tension: 1500 }}
                            rotation={[my, mx, 0]}

                            polar={[-Math.PI / 3, Math.PI / 3]}
                            azimuth={[-Math.PI / 1.4, Math.PI / 2]}>
                            <Model />
                        </PresentationControls>

                        <ContactShadows rotation-x={Math.PI / 2} position={[0, -1.1, 0]} opacity={0.3} />
                        <Environment  preset="city"  />
                        
                        <AdaptiveDpr pixelated />
                 
                </Canvas>

            </Box>
        </Box>
    )
}

