import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from '@react-three/fiber'
export default function Model(props) {
  const ref = useRef();
    const { nodes, materials } = useGLTF("/relteco2.glb");
 useFrame((state) => {
    const t = state.clock.getElapsedTime()*1.5
     ref.current.rotation.z = (1 + Math.sin(t / 1.5)) / 20
     ref.current.position.y = (1 + Math.sin(t / 1.5)) / 10     
 })


       
        

  


    
  return (
    <group ref={ref} {...props} dispose={null}>
    
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Eyeball_Segment_Low_Poly.geometry}
        material={nodes.Eyeball_Segment_Low_Poly.material}
        rotation={[-0.06, -0.03, 0]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Armature002.geometry}
          material={materials["Metal.001"]}
          position={[0, 0, -0.83]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Battery001.geometry}
          material={nodes.Battery001.material}
          position={[0, 0, 1.44]}
          scale={0.71} material-color="orange"
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Battery002.geometry}
          material={nodes.Battery002.material}
          position={[0, 0, 1.44]}
          rotation={[0, 0, -2.09]}
          scale={0.71}
          material-color="orange"
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.BezierCurve005.geometry}
          material={nodes.BezierCurve005.material}
          position={[-0.38, 0.82, 0.9]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Battery.geometry}
          material={nodes.Battery.material}
          position={[0, 0, 1.44]}
          rotation={[0, 0, 2.09]}
          scale={0.71}
        />
        <group position={[0, 0, 1.44]} scale={0.71}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Sphere026.geometry}
            material={materials["Camera Plastic"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Sphere026_1.geometry}
            material={materials["Camera Lens"]}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Clip.geometry}
          material={materials.Metal}
          position={[0, -0.7, -0.91]}
          rotation={[-0.95, 0, 0]}
          scale={[0.14, 0.06, 0.06]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Eyeball_Segment_Low_Poly001.geometry}
          material={nodes.Eyeball_Segment_Low_Poly001.material}
          rotation={[0, 0, -2.09]}

           
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Eyeball_Segment_Low_Poly002.geometry}
          material={nodes.Eyeball_Segment_Low_Poly002.material}
          rotation={[0, 0, 2.09]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Lens_Low_Poly.geometry}
          material={materials.Glass} material-color="orange"
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Light_Low_Poly.geometry}
          material={materials.Light} 
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Circle_Low_Poly.geometry}
          material={materials["Black Plastic"]}
        />
      </mesh>
    </group>
  );
}

useGLTF.preload("/relteco2.glb");