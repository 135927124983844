import { Typography,useTheme,Button } from '@mui/material'
import React, { useState } from 'react'

export const MenuButton = ({text,onClick,mode="lt"}) => {
    const theme = useTheme()
    const [moden,setMode]=useState({
      lt:{fontSize:16,marginLeft:3},
      lf:{fontSize:22,marginTop:3,width:"100%"},

    })

  return  <Button onClick={onClick} variant="text"  
  sx={[{textTransform: 'none',fontWeight:"bold",color:theme.palette.text.primary,fontFamily:"'Crimson Pro', serif"},mode==="lt"?moden.lt:moden.lf]} >{text} </Button>
}
