import React, { useEffect, useState } from 'react'
import { InLayout } from '../Layouts/InLayout'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import { DB } from '../Functions/DB';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export  function DownloadsPage() {

  const [data,setData]=useState()

  const {GetDownloads}=DB()


  useEffect(()=>{
    GetDownloads("",[],r=>{
      setData(r.data)

    })
  },[])

  return (
   <InLayout  subtitle={"downloadPage.subtitle"} title={"downloadPage.title"}  >
  <TableContainer sx={{maxWidth:900}} component={Paper}>
      <Table size="small" sx={{ minWidth: 550}} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Ad</TableCell>
            <TableCell align="right">Açıklama</TableCell>
            <TableCell align="right">Version</TableCell>
            <TableCell align="right">Url</TableCell>
            <TableCell align="right">Tarih</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data&&data.map((row) => (
          
            <TableRow
              key={row.d_name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.d_name}
              </TableCell>
              <TableCell align="right">{row.d_context}</TableCell>
              <TableCell align="right">{row.d_version}</TableCell>
              <TableCell align="right">{row.d_url}</TableCell>
              <TableCell align="right">{ moment.utc(row.d_ddate).format('MM/DD/YY')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
   </InLayout>
  )
}
