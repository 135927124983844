import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { InLayout } from '../Layouts/InLayout';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, Button, CardActionArea, CardActions } from '@mui/material';
import { DB } from '../Functions/DB';
import { ApiConfig } from '../Functions/AXIOS/ApiConfig';


function PCard({img,title,context}) {
  const { t, i18n } = useTranslation();
 
  const domain = ApiConfig.file


  return(
    <Card sx={{ width: 300,float:"left",m:2 }}>
    <CardActionArea>
      <CardMedia
        component="img"
        height="180"
        
        image={domain+img}
        alt="green iguana"
      />
      <CardContent>
        <Typography sx={{fontFamily:"'Crimson Pro', serif"}} gutterBottom variant="h5" component="div">
          {title} 
        </Typography>
        <Typography sx={{fontFamily:"'Crimson Pro', serif"}} variant="body2" color="text.secondary">
          {context} 
        </Typography>
      </CardContent>
    </CardActionArea>
    <CardActions>
      <Button size="small" sx={{color:"orange"}} >
       { t('menu.login')}
      </Button>
    </CardActions>
  </Card>
  )
}


export  function ProductsPage() {

const {GetProducts}=DB()
const [p,setP]=useState()


  useEffect(()=>{
    GetProducts("",{},r=>{
      // console.log(r.data)
      setP(r.data)
    })
  },[])

  return (
    <InLayout subtitle={"productPage.subtitle"} title={"productPage.title"} >

      <Box sx={{}} >
        {
          p&&p.map((d,i)=>{
            return(
              <React.Fragment key={i}>
                <PCard title={d.p_name} context={d.p_context} img={d.p_img} />
              </React.Fragment>
            )
          })
        }

      </Box>
    </InLayout>

  )
}
