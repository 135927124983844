import { Avatar, Box, Typography,useTheme } from '@mui/material'
import React from 'react'
import { useNavigate } from "react-router-dom";


export const LogoView = () => {
const navigate = useNavigate();
const theme = useTheme()

  return (
    <Box  onClick={()=>navigate("/")} sx={{width:150, position:"fixed",left:1,top:10,zIndex:999,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",cursor:"pointer"}} >
    <Avatar  sx={{ width: 40, height: 40 }} src='/logo512.png' />
    <Typography color={theme.palette.text.primary} sx={{marginLeft:1,fontSize:30,fontWeight:"bold",fontFamily:"'Markazi Text', serif",userSelect:"none",alignSelf:"center"}} variant='subtitle2' >
    RELTECO
    </Typography>

    </Box>
  )
}
