
import './App.css';
import R_Routes from './Routes';
import React, { Suspense } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useToggleTheme } from './Hooks/usetheme';
import themeModel from './Themes/theme';


function App() {
  const { themeMode } = useToggleTheme()
  const theme = React.useMemo(() => createTheme(themeModel(themeMode)), [themeMode]);


  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<p>Yükleniyor....</p>} >
        <R_Routes />
      </Suspense>
    </ThemeProvider>

  );
}

export default App;
