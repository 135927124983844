import { Avatar, Box, Typography, useTheme } from "@mui/material"
import React from 'react'
import { MenuView } from "./MenuView"
import { LogoView } from "./LogoView"

export const Appbar = () => {
    const theme = useTheme()
  return (

    
  
    <Box sx={{width:'100%',height:45,display:'flex',justifyContent:'center',color:'#ccc',backgroundColor:theme.palette.background.paper,alignItems:'center',position:'fixed',top:10,zIndex:8}} >
      <LogoView />
      <MenuView />
       </Box>
    
  )
}
