import axios from "axios";
import {  enqueueSnackbar, useSnackbar } from 'notistack';
import { ApiConfig } from "./ApiConfig";

 

  const cfg = ApiConfig.apis.base,
  ssl = cfg.ssl ? "https://" : "http://",
    domain = cfg.domain.dev,
    port = cfg.port.dev,
    BASEURL = ssl + domain + ":" + port;




export const AXIOS =()=>{



  

    async function postUrlData(h,url,context,callback) {
   
      await  axios
      .post(url, context, { headers:h})
      .then((response) => {
        callback(response.data);
      })
      .catch(error => {
        enqueueSnackbar('Bağlantı hatası. İnternet bağlantınızı kontrol edin.', { variant: 'error' })
     });
    }


    function AlertMessage(msg,code) {
      switch (code) {
        case 0:
          enqueueSnackbar(msg, { variant: 'success' })
          break;
          case 1:
            enqueueSnackbar(msg, { variant: 'error' })
          break;
          case 2:
            enqueueSnackbar(msg, { variant: 'warning' })
          break;
      
        default:
          break;
      }
    }

    function Responsive(h, endpoint ,data,callback){
      let url = BASEURL+endpoint
      console.log(url)
      postUrlData(h, url ,data,(res)=>{
                 AlertMessage(res.msg,res.code)
                //  console.log(res)
                 callback(res)
               })
    }


  return{Responsive}

}