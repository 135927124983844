import React from 'react'
import { Routes, Route, useNavigate } from "react-router-dom";

import { HomePage, ContactPage, DownloadsPage, ProductsPage, SolutionsPage } from '../Pages'
import { MainLayout } from '../Layouts'

export default function R_Routes() {



    const Pages = [
        { name: "/", page: <HomePage /> },
        { name: "/SolutionsPage", page: <SolutionsPage /> },
        { name: "/ProductsPage", page: <ProductsPage /> },
        { name: "/DownloadsPage", page: <DownloadsPage /> },
        { name: "/ContactPage", page: <ContactPage /> },
    ]
    function Error404() {
        return (
          <div>
            <h2>Aradığınız Sayfa Bulunamadı</h2>
          </div>
        )
      }




    return (
        <>
            <Routes>



                <Route element={<MainLayout />} >
                    {
                        Pages.map((d, i) => {
                            return (
                                <React.Fragment key={i} >
                                    <Route path={d.name} element={d.page} />
                                </React.Fragment>
                            )
                        })
                    }

                </Route>



                <Route path="*" element={<Error404 />} />
            </Routes>

        </>
    )
}
