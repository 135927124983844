import { Box, Avatar, useTheme,IconButton, Typography } from '@mui/material'
import React from 'react'
import { useOutlet } from 'react-router-dom'
import { useToggleTheme } from '../Hooks/usetheme'
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { Appbar } from '../Components';
export const MainLayout = ({ children }) => {
    const { themeMode, toggleThemeMode } = useToggleTheme()
    const theme = useTheme()
    const outlet = useOutlet()
    const date = new Date()


    return (

        <Box sx={{ display: 'flex', backgroundColor: theme.palette.background.paper, width: "100%", height: "100vh", flex: 1, justifyContent: "center", padding: 1 }} >
            <Appbar />
            <Box sx={{ position: "absolute", zIndex: 300 }} >
                {children}
            </Box>
            {outlet}

            <Box sx={{ width: '100%', height: 50, display: 'flex', justifyContent: 'flex-start', color: '#ccc', backgroundColor: theme.palette.background.paper, alignItems: 'center', position: 'fixed', bottom: 0, zIndex: 888 }} >
                {/* <Avatar  sx={{ width: 26, height: 26 }} src='/logo192.png' /> */}
<Typography color={theme.palette.text.secondary} sx={{marginLeft:2,fontSize:15,fontFamily:"'Markazi Text', serif"}} variant='subtitle2' >
RELTECO © { date.getFullYear()}
</Typography>
                <IconButton
                    color="default"
                    aria-label="open drawer"
                    onClick={toggleThemeMode}
                    edge="start"
                    size="small"

                    sx={{ position: "fixed", right: 15, bottom: 10, zIndex: 10 }}
                >
                    {themeMode ? <LightModeIcon /> : <DarkModeIcon />}
                </IconButton>
            </Box>
        </Box>

    )
}
