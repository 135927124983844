import { Typography, useTheme } from '@mui/material'
import React from 'react'



export  function Rtext({fs=16,fw,text,p=2,c}) {
   const theme =  useTheme()

    return <Typography sx={{p:p,color:c?c:theme.palette.text.primary,fontSize:fs,fontWeight:fw,userSelect:"none",fontFamily:"'Crimson Pro', serif"}} >{text}</Typography>

}
